// IMPORTS
@import (reference) "../settings.less";

// ALTERNATING BANNERS
.alternating-banners-wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--rowGap);
}

.alternating-banner {
    display: flex;
    justify-content: center;
    align-items: center;

    &:has(nth-child(2)) {
        justify-content: space-between;
    }

    figure {
        width: 50%;
        aspect-ratio: ~"720/540";
        align-self: flex-start;
        position: sticky;
        top: 24px;

        img {
            .img-crop();
        }
    }

    .text-wrapper {
        width: 50%;
        flex-direction: column;
        padding: 0 0 0 9.7%;

        &:only-child {
            width: 70%;
            padding: 0 !important;
            text-align: center;

            > button {
                margin: 0 auto;
            }
        }

        > b {
            .headline();
            display: block;
        }

        > div {
            padding-bottom: 32px;
        }

        > button {
            display: block;
            font-weight: 700;
            color: @header-color;
            padding: 16px 32px;
            line-height: 1.2;
            font-size: 14px;
            text-transform: uppercase;
            border: 1px solid @main-color;

            &:hover {
                border-color: @header-color;
            }
        }
    }

    &:nth-child(even) {
        .text-wrapper {
            order: -1;
            padding-left: 0;
            padding: 0 9.7% 0 0;
        }
    }
}